import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import React from "react";
import { Resources, useResource } from "Translations/Resources";
import { MerchantInitiateComponent } from "Components/Merchant/Initiate/MerchantInitiateComponent";
import { useSearchParams } from "react-router-dom";

const MenuResources = Resources.Menu.MainMenuItems;

export const MerchantInitiatePaymentPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const { t } = useResource();
  const [searchParams] = useSearchParams();

  const publicID = searchParams.get("publicID");

  return (
    <AuthenticatedLayout title={t(MenuResources.InitiatePayment)}>
      <StyledPageWrapper>
        <MerchantInitiateComponent publicID={publicID ?? undefined} />

        <br />
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default MerchantInitiatePaymentPage;
