import { ResourceDictionary } from "./Resources";
import zodTranslation from "zod-i18n-map/locales/cs/zod.json";

export const CzechResourcesDictionary: ResourceDictionary = {
  Common: {
    AppName: "Moone",
    Amount: "Částka",
    Note: "Poznámka",
    Unknown: "Neznámý",
    ShowAll: "Zobrazit vše",
    ShowMore: "Zobrazit více",
    VariableSymbol: "Variabilní symbol",
    Required: "Povinné pole",
    InvalidIBAN: "Neplatný IBAN",
    CancelEdit: "Zrušit úpravy",
    Cancel: "Zrušit",
    Confirm: "Potvrdit",
    NiceDay: "Hezký den",
    Send: "Odeslat",
  },
  Errors: {
    RequestError: "Během požadavku nastala chyba",
    ApplicationError: {
      Title: "Nastala chyba",
      Home: "Domů",
    },
    Error404: {
      Subtitle: "Stránka nebyla nalezena",
      Home: "Domů",
    },
    Error403: {
      Subtitle: "K zobrazení této stránky nemáte přístupová práva",
    },
  },
  SignIn: {
    Error: {
      General: "Nastala chyba",
      InvalidLogin: "Špatné heslo nebo e-mail",
      InvalidCredentials: "Špatné heslo nebo e-mail",
      ApplicationVerification: "Špatné heslo nebo e-mail",
      PhoneNumberNotFound: "Telefonní číslo nebylo nalezeno",
      SmsSendingFailed: "Zlyhalo posílání SMS ",
      InvalidToken: "Navalidní token",
      AccountNotFound: "Špatné heslo nebo e-mail",
      AccountIsDeactivated:
        "Účet byl deaktivován. Jestli chcete účet obnovit, kontaktujte podporu.",
    },
  },
  SignUp: {
    Error: {
      General: "Nastala chyba",
      UserExists: "Účet již existuje",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  Validation: {
    Required: "Vyplňte pole",
    InvalidEmail: "E-mail je nevalidní",
  },
  Menu: {
    MainMenuItems: {
      Overview: "Přehled",
      InitiatePayment: "Vyvolat platbu",
      History: "Historie",
      Accounts: "Účty",
      Settings: "Více",
    },
    BottomMenuItems: {
      Overview: "Přehled",
      History: "Historie",
      ActionButton: "Zaplatit",
      Accounts: "Účty",
      Settings: "Více",
    },
  },
  Transactions: {
    Title: "Transakce",
    Payment: {
      Incoming: "Příchozí platba",
      Outgoing: "Odchozí platba",
    },
    Type: {
      Buy: "Nákup",
      Sell: "Prodej",
      Withdrawal: "Výběr",
      LeadReward: "Odměna za doporučení",
      LeadRewardConsumer: "Odměna za registraci",
      Voucher: {
        GiftCard: "Dárková karta",
        SignUpSubsidy: "Dárek k registraci",
      },
      Coinback: "Coinback",
    },
    ErrorPayment: {
      Title: "Nastala chyba",
      Description: "Je nám to líto, ale během platby došlo k problému.",
    },
    SuccessPayment: {
      Title: "Úspěšně zaplaceno!",
      DescriptionFirstPart: "Peníze byly úspěšně odeslány",
      DescriptionSecondPart: "na obchodníkův účet.",
    },
    Anonymous: {
      DownloadReceipt: "Stáhnout účtenku do zařízení",
      FillEmail: "Zadejte e-mail, abychom Vám mohli zaslat účtenku.",
    },
    Status: {
      Completed: "Provedeno",
      PendingTrade: "Zpracovává se",
      PendingPayout: "Čeká na potvrzení eshopem",
      WithdrawRequested: "Probíhá výběr",
      Withdrawn: "Provedeno",
    },
    TransactionStatus: {
      Cancelled: "Zrušená",
      Fail: "Selhala",
      Initiated: "Čeká na zaplacení",
      Processing: "Probíhá zpracování",
      Success: "Úspěšně přijata",
    },
    InstructionStatus: {
      AcceptedCustomerProfile: "Platba schválena",
      AcceptedSettlementCompleted: "Platba dokončena",
      AcceptedTechnicalValidation: "Přijato pro zpracování",
      None: "Neznámý",
      Rejected: "Platba zamítnuta",
      AcceptedWithChange: "Platba byla přijata, ale došlo ke změně údajů.",
    },
    InstructionStatusDescription: {
      AcceptedCustomerProfile:
        "Vaše platba byla úspěšně schválena a čeká na další kroky.",
      AcceptedSettlementCompleted:
        "Vaše platba byla úspěšně dokončena a peníze byly převedeny.",
      AcceptedTechnicalValidation:
        "Vaše platba byla přijata a je připravena ke zpracování.",
      None: "Stav platby není známý. Prosíme, kontaktujte nás pro více informací.",
      Rejected:
        "Vaše platba byla zamítnuta. Může to být kvůli nesprávným údajům nebo nedostatečným prostředkům.",
      AcceptedWithChange:
        "Vaše platba byla přijata, ale došlo ke změně údajů (např. datum nebo informace o platbě nebyly zaslány). Prosím, zkontrolujte detaily transakce.",
    },
  },
  SetPassword: {
    Error: {
      General: "Nastala chyba.",
      TokenNotFound: "Token je navalidní",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  BankIDVerification: {
    Error: {
      General:
        "Autentifikace přes BankID se nezdařila. Zkuste to prosím znovu.",
      BankIDUserInfoError: "Nepodařilo se získat data z BankID.",
      VerificationAlreadyExists: "Uživatel z BankID byl již ověřen.",
    },
  },
  Voucher: {
    ApplyError: {
      General: "Nastala chyba",
      NotFound:
        "Zadaný kód není správný. Zkontrolujte, zda je správně opsaný. Pokud problémy přetrvávají, kontaktujte nás.",
      Applied: "Kupon byl použit",
      UserAccountNotFound: "Uživatel nenalezen",
      UserNotFound: "Uživatel nenalezen",
      UserIsWaitingForEmailVerification: "Uživatel nenalezen",
      InvalidRecaptcha:
        "Recaptcha není validní. Refreshnite aplikaci a zkuste to znovu.",
    },
  },
  Balance: {
    Title: "Přehled",
    BalanceContent: {
      Title: "Hodnota účtu",
      PayButton: "Zaplatit",
      SendPaymentButton: "Poslat platbu",
    },
    NoAcount: {
      Title: "Začínáme",
      Description:
        "Abyste mohli začít platit Moonem, je potřeba připojit bankovní účet, ze kterého budete platby provádět. Nebojte, toto připojení je na pár kliknutí!",
      ActionButton: "Připojit účet",
    },
  },
  Banking: {
    Auth: {
      Title: "Připojit účet",
      Description: "Vyberte si banku, kterou chcete připojit.",
      BankList: {
        Title: "Nabídka bank",
        ComingSoon: "Již brzy",
      },
    },
  },
  BankNames: {
    AirBank: "Air Bank",
    CSAS: "Česká spořitelna",
    CSOB: "ČSOB",
    KB: "Komerční banka",
    UCB: "UniCredit Bank",
  },
  Merchant: {
    Payment: {
      Title: "Dnešní tržba",
      InitiatePaymentButton: "Vyvolat platbu",
    },
    Initiate: {
      GenerateButton: "Generovat údaje",
      Form: {
        AccountSelectTitle: "Výběr podnikatelského účtu",
      },
    },
    InitiateDetail: {
      Title:
        'Zaplatit můžete oskenováním QR kódu, nebo vložením kódu transakce ve formuláři "Zaplatit".',
      PaymentStatus: "Status platby",
      TransactionPaymentCode: "Kód transakce pro platbu",
    },
  },
  Customer: {
    Pay: {
      Title: "Zaplatit",
      DescriptionText:
        "Zaplatit můžete i pomocí oskenování QR kódu protistrany.",
      PayButton: "Zaplatit",
      ChooseBankAccount: "Vyberte si účet, kterým chcete zaplatit",
      ChooseBank: "Vyberte si banku, kterou chcete zaplatit",
      AddNextBankAccount: "Přidat další účet",
    },
  },
  BankAccounts: {
    List: {
      Description:
        'Tyto bankovní účty jsou spárovány s Vaším účtem. Pokud chcete přidat další bankovní účet, klikněte na tlačítko "Spárovat bankovní účet."',
      NoAccounts: "Nemáte žádné spárované bankovní účty.",
      SaveOrder: "Uložit pořadí",
      EditOrder: "Upravit pořadí",
      AddAccount: "Spárovat bankovní účet",
    },
  },
  zod: zodTranslation,
};
