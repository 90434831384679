import {
  PasswordlessSignUpCommand,
  PasswordlessSignUpCommandResult,
  postAuthSignUpPasswordless,
  PostAuthSignUpPasswordlessFetchResponse,
  SignInResult,
  SignInStatus,
} from "Infrastructure/Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { signInAsync } from "State/Auth/SignIn/SignInState";
import { getCodeListCollectionAsync } from "State/CodeLists/CodeListReducer";
import { setToken } from "Utils/LocalStorageUtils";
import { LocalStorageKeys } from "Utils/LocalStorageKeys";
import { setSignUpSubsidy } from "State/Voucher/VoucherReducer";
import { signUpAsync } from "State/Auth/SignUp/SignUpState";
import { afterSignInSuccess } from "Utils/AuthUtils";
import { trackUserIdentification } from "Utils/TrackingUtils";
import { HttpStatusCode } from "Models/HttpStatusCodes";

export const signUpPasswordlessAsync = createAsyncAction(
  "@auth/SIGN_UP_PASSWORDLESS_REQUEST",
  "@auth/SIGN_UP_PASSWORDLESS_SUCCESS",
  "@auth/SIGN_UP_PASSWORDLESS_FAILURE",
)<
  {
    model: PasswordlessSignUpCommand;
    successCallback: (
      response: PostAuthSignUpPasswordlessFetchResponse,
    ) => void;
    failedCallback: (
      response: PostAuthSignUpPasswordlessFetchResponse | Error,
    ) => void;
  },
  PasswordlessSignUpCommandResult,
  Error
>();

function* signUpPasswordlessState(
  action: ReturnType<typeof signUpPasswordlessAsync.request>,
): Generator {
  try {
    const signUpResponse = yield* call(
      postAuthSignUpPasswordless,
      action.payload.model,
    );
    if (signUpResponse.status === 200) {
      const signInResult = signUpResponse.data.signInResult as SignInResult;
      if (
        !!signInResult &&
        signInResult.status === SignInStatus.Success &&
        !!signUpResponse.data.signInResult
      ) {
        localStorage.removeItem(LocalStorageKeys.VoucherCodeSubsidy);

        setToken(signInResult);

        yield put(getCodeListCollectionAsync.request());
        trackUserIdentification();

        yield put(signInAsync.success(signInResult));
        yield* call(afterSignInSuccess, signUpResponse.data.signInResult);

        if (
          !!signUpResponse.data.subsidyFiatAmount &&
          !!signUpResponse.data.subsidyFiatCurrencyCode
        ) {
          yield put(
            setSignUpSubsidy({
              fiatAmount: signUpResponse.data.subsidyFiatAmount,
              currencyCode: signUpResponse.data.subsidyFiatCurrencyCode,
            }),
          );
        }

        action.payload.successCallback(signUpResponse);
      } else {
        const error = new Error(signUpResponse.data.status, {
          cause: HttpStatusCode.Unauthorized,
        });

        yield put(signUpAsync.failure(error));
        action.payload.failedCallback(signUpResponse);
      }
    } else {
      const error = new Error();
      yield put(signUpPasswordlessAsync.failure(error));
      action.payload.failedCallback(signUpResponse);
    }
  } catch (err) {
    yield put(signUpPasswordlessAsync.failure(err as Error));
    action.payload.failedCallback(err as Error);
  }
}
export function* signUpPasswordlessSaga() {
  yield takeLatest(
    getType(signUpPasswordlessAsync.request),
    signUpPasswordlessState,
  );
}
