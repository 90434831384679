import { useSignUpPasswordless } from "Api/Mutations/Auth/useSignUpPasswordless";
import { useTransactionsDetail } from "Api/Queries/Transactions/useTransactionsDetail";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CommonError } from "Components/Shared/Errors/CommonError";
import { PayPaymentDetail } from "Components/Shared/Payments/PayPaymentDetail";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { UserStatus } from "Infrastructure/Api/Api";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  transactionPublicID: string;
  onBankSelector: () => void;
};

const ComponentResources = Resources.Customer.Pay;

export const AnonymousCustomerPaymentPayComponent: FC<Props> = ({
  transactionPublicID,
  onBankSelector,
}) => {
  const { t } = useResource();
  const user = useSelector((state: RootStateType) => state.auth.user);
  const { data, isLoading, isError } =
    useTransactionsDetail(transactionPublicID);
  const { mutate: signUpPasswordLess, isPending } = useSignUpPasswordless({
    onSignUpSuccess: onBankSelector,
  });

  const onPay = () => {
    if (user?.userStatus !== UserStatus.Anonymous) {
      signUpPasswordLess();
    } else {
      onBankSelector();
    }
  };

  if (isError) {
    return <CommonError />;
  }

  return (
    <PrimaryPaper>
      <PayPaymentDetail
        isError={isError}
        isLoading={isLoading}
        transactionDetail={data}
      />
      <PrimaryButton
        color="primary"
        fullWidth
        onClick={onPay}
        isLoading={isPending}
      >
        {t(ComponentResources.PayButton)}
      </PrimaryButton>
    </PrimaryPaper>
  );
};
